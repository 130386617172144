<template>
  <div class="footer-start position-relative">
    <VerticalLines color="#46b3ff" />
    <div class="container">
      <div class="footer-grid">
        <div class="title-section">
          <h4 class="title">{{ $t("heading") }}</h4>
          <div class="subline">
            {{ $t("subLine") }}
          </div>
        </div>
        <div class="claim-section">{{ $t("bottomClaim") }}</div>
        <div class="phone-section">
          <a href="tel:+49631205755221" class="phone">{{ $t("phone") }}</a>
        </div>
        <div class="team-section">
          <img
            class="w-100"
            src="@/assets/img/startseite/footer/team99.jpg"
            :alt="$t('teamImageAlt')"
          />
        </div>

        <div class="map-section">
          <a
            target="_blank"
            href="https://www.google.com/maps/place/MEDIAFORMAT+GmbH/@49.4292847,7.7504789,15.75z/data=!4m5!3m4!1s0x47966d52a3578a79:0xdc80329db92922a9!8m2!3d49.4294318!4d7.7513051?hl=de&shorturl=1"
          >
            <img class="w-100" :src="$t('mapImage')" :alt="$t('mapImageAlt')" />
          </a>
        </div>

        <address class="address-section">
          <strong>MEDIAFORMAT GmbH</strong><br />
          <span>{{ $t("address") }}</span>
        </address>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalLines from "@/components/layouts/VerticalLines";
import mapDE from "@/assets/img/startseite/footer/desktop-map-MF.svg";
import mapEN from "@/assets/img/startseite/footer/desktop-map-MF-EN.svg";

export default {
  name: "FooterStart",
  components: { VerticalLines },
  i18n: {
    messages: {
      de: {
        heading: `Let's talk!`,
        subLine: `Definieren Sie Ihr digitales Bedürfnis und schauen Sie doch einfach mal vorbei.`,
        teamImageAlt: `Team MEDIAFORMAT GmbH`,
        bottomClaim: `Wir freuen uns auf Ihren Besuch.`,
        address: `Trippstadter Str. 122 / 67663 Kaiserslautern`,
        phone: `0631 205 755 221`,
        mapImageAlt: `Lageplan MEDIAFORMAT GmbH`,
        mapImage: mapDE
      },
      en: {
        heading: `Let's talk!`,
        subLine: `Let us know your digital needs and together we will explore a range of solutions.`,
        teamImageAlt: `Team MEDIAFORMAT GmbH`,
        bottomClaim: `We look forward to getting to know you.`,
        address: `Trippstadter Str. 122 / 67663 Kaiserslautern / Germany`,
        phone: `+49 631 205 755 221`,
        mapImageAlt: `Site plan MEDIAFORMAT GmbH`,
        mapImage: mapEN
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";

.footer-start {
  background-color: $main-color;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding: 50px 0;

  @include breakpoint(md) {
    padding: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 0;
    grid-template-areas:
      "title team"
      "phone team"
      "claim map"
      "address map";

    .title-section {
      grid-area: title;
    }
    .claim-section {
      grid-area: claim;
    }
    .phone-section {
      grid-area: phone;
    }
    .address-section {
      grid-area: address;
    }
    .team-section {
      grid-area: team;
    }
    .map-section {
      grid-area: map;
    }
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "title team map map"
      "title claim map map"
      "title phone map map"
      "title address map map";
  }

  .title-section {
    text-align: center;
    color: $black;
    margin-bottom: 25px;

    .title {
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      margin: 0;
      line-height: 50px;
    }
    .subline {
      font-size: $font-size-medium;
      font-weight: $font-weight-light;
    }
    @include breakpoint(md) {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .subline {
        font-size: $font-size-base;
      }
    }
    @include breakpoint(lg) {
      justify-content: flex-start;
      padding-top: 75px;
      .title,
      .subline {
        font-size: $font-size-footer;
      }
    }
  }
  .claim-section {
    display: none;

    @include breakpoint(md) {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-weight: $font-weight-bold;
      font-size: $font-size-md;
      margin-bottom: 25px;
    }
    @include breakpoint(lg) {
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
    }
  }
  .phone-section {
    text-align: center;
    margin-bottom: 25px;
    .phone {
      font-size: $font-size-md;
      color: $white;
      font-weight: $font-weight-bold;
      position: relative;
    }

    @include breakpoint(md) {
      text-align: left;
    }
    @include breakpoint(lg) {
      margin-bottom: 0;
      .phone {
        color: $black;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        padding-left: 30px;
        background: url(../../assets/img/startseite/footer/footer-phone.svg)
          no-repeat 5px center;
        background-size: 20px 20px;
      }
    }
  }
  .address-section {
    font-size: $font-size-xs;
    line-height: $line-height-base;
    text-align: center;
    margin-top: 50px;

    span {
      font-weight: $font-weight-light;
    }
    @include breakpoint(md) {
      margin-top: 0;
      text-align: left;
      font-size: $font-size-sm;
    }
  }
}
</style>

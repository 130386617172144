<template>
  <section class="position-relative vh-100">
    <div class="head-video position-absolute overflow-hidden">
      <video
        v-if="showVideo"
        ref="video"
        poster="@/assets/img/startseite/bg-work.jpg"
        class="min-vw-100 min-vh-100 d-block w-auto h-auto position-absolute"
        controls
        autoplay
        muted
        playsinline
        loop
      >
        <source src="@/assets/video/background.mp4" type="video/mp4" />
        <source src="@/assets/video/background.ogg" type="video/ogg" />
      </video>
    </div>
    <VerticalLines lines-color="#333539" />
    <div class="h-100 d-flex align-items-center">
      <div class="container h-auto">
        <div class="v-grid">
          <div class="col-offset col-2">
            <div class="teaser position-relative">
              <ul class="bullet-points pl-0 m-lg-0  d-flex main-text">
                <li class="bullet-point d-flex position-relative">
                  {{ $t("b1")
                  }}<span
                    class="bullet d-flex justify-content-center align-items-center"
                  />
                </li>
                <li class="bullet-point d-flex position-relative">
                  {{ $t("b2")
                  }}<span
                    class="bullet d-flex justify-content-center align-items-center"
                  />
                </li>
                <li class="bullet-point d-flex position-relative">
                  {{ $t("b3") }}<span class="bullet" />
                </li>
              </ul>
              <span class="d-block mb-sm-1 subtitle main-text">{{
                $t("sub")
              }}</span>
              <p class="mt-1 teaser-text">
                {{ $t("slogan") }}
              </p>
            </div>
            <div class="your-partner" v-html="$t('yourPartner')"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer position-absolute">
      <AnimatedScrollDown @scrollDown="$emit('scrollDown')" />
    </div>
  </section>
</template>
<script>
import AnimatedScrollDown from "@/components/startseite/AnimatedScrollDown";
import { $mqMixin } from "@/utils/mixins";
import VerticalLines from "@/components/layouts/VerticalLines";
import { getClientHeight } from "@/utils/animationHelper";
import pretzel from "@/assets/img/startseite/pretzel.svg";

const prezelImg = `<img
  style="width: 50px;height: auto;"
  src="${pretzel}"
/>`;

export default {
  name: "IntroVideo",
  components: {
    VerticalLines,
    AnimatedScrollDown
  },
  mixins: [$mqMixin],
  computed: {
    showVideo() {
      return ["md", "lg", "xl"].includes(this.mq);
    }
  },
  methods: {
    scrollDown() {
      window.scrollTo({
        top: getClientHeight(),
        left: 0,
        behavior: "smooth" // not supported by safari
      });
    },
    videoPlay() {
      this.$refs.video.muted = true;
      this.$refs.video.autoplay = true;
      this.$refs.video.load();
      if (this.showVideo) {
        setTimeout(() => {
          this.$refs.video.play();
        }, 1000);
      }
    }
  },
  mounted() {
    this.videoPlay();
  },
  activated() {
    this.videoPlay();
  },
  i18n: {
    messages: {
      de: {
        b1: "Konzept",
        b2: "Design",
        b3: "Technologie",
        sub: "im perfekten Zusammenspiel.",
        slogan:
          "Ihr Partner für die Entwicklung wirkungsvoller Softwareprodukte und Apps – qualifiziert, transparent und am Puls der Zeit.",
        yourPartner: ` `
      },
      en: {
        b1: "Concept",
        b2: "Design",
        b3: "Technology",
        sub: "in perfect harmony.",
        slogan:
          "Our teams help you develop effective software products and apps – experienced, transparent and cutting-edge.",
        yourPartner: `Your ${prezelImg} German tech partner`
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../styles/imports";
.head-video {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  background: url(../../assets/img/startseite/mobile.png) 50% no-repeat;
  background-size: cover;
  video {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    &::-webkit-media-controls-panel {
      display: none;
    }
  }
}
.teaser {
  max-width: 870px;
  line-height: $line-height-base;
}

.main-text {
  font-size: $font-size-base;
  @media screen and (orientation: portrait) {
    font-size: $font-size-lg;
  }

  @include breakpoint(md) {
    font-size: $font-size-md;
  }
  @include breakpoint(lg) {
    font-size: $font-size-lg;
  }
}

.footer {
  bottom: 0;
  left: 0;
  right: 0;
}

.subtitle {
  font-size: $font-size-md;
  font-weight: $font-weight-normal;
  color: $primary;
  @include breakpoint(md) {
    font-size: $font-size-xl;
  }
}

.bullet-points {
  list-style: none;
  flex-direction: column;
  justify-content: stretch;
  @include breakpoint(lg) {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.teaser-text {
  font-size: $font-size-medium;
  max-width: $maxContainerSize;
  @include breakpoint(md) {
    font-size: $font-size-base;
  }
}

.bullet-point {
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $primary;

  &:last-of-type .bullet {
    display: none;
  }
  @include breakpoint(lg) {
    font-size: $font-size-xl;
  }
}

.bullet {
  width: 30px;
  display: none;
  &:after {
    display: none;
  }
  @include breakpoint(lg) {
    display: block;
    &:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      margin-top: 23px;
      background-color: $white;
      border-radius: 50%;
    }
  }
  @include breakpoint(md) {
    width: 60px;
    height: 60px;
  }
}

.your-partner {
  margin-top: 25px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
}

@media (max-width: 813px) and (orientation: landscape) {
  .bullet-point,
  .subtitle {
    font-size: $font-size-md;
  }
  .bullet {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      margin-top: 0;
      background-color: $white;
      border-radius: 50%;
    }
  }
  .bullet-points {
    margin-top: 50px;
  }
}
</style>

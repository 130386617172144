<template>
  <router-link :to="{ name: story.route }" class="story" :style="storyStyle">
    <div
      class="story-text"
      @mouseenter="activate"
      :class="{ active: isActive }"
    >
      {{ story.title }}
    </div>
  </router-link>
</template>
<script>
export default {
  name: "StoryItem",
  props: {
    value: {
      type: String
    },
    story: {
      type: Object,
      required: true
    }
  },
  computed: {
    isActive() {
      return this.value === this.story.route;
    },
    storyStyle() {
      return {
        backgroundImage: `url(${this.story.bg})`
      };
    }
  },
  methods: {
    activate() {
      this.$emit("input", this.story.route);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/imports";

.story {
  position: relative;
  display: block;
  background-size: cover;
  height: 100%;
  min-height: 300px;

  .story-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: opacity 0.2s ease;
    background-color: rgba(5, 164, 255, 0.8);
    font-weight: $font-weight-bold;
  }

  @include breakpoint(md) {
    min-height: 360px;

    .story-text {
      opacity: 0;
      cursor: pointer;
      text-align: center;

      &.active {
        opacity: 1;
      }
    }
  }
}
</style>

<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    style="margin-bottom: 5px"
    @click="$emit('scrollDown')"
  >
    <div class="mouse-animation position-relative mb-1" />
    <ArrowDownIcon class="head-arrow" />
  </div>
</template>
<script>
import ArrowDownIcon from "@/components/icons/ArrowDownIcon.vue";

export default {
  name: "AnimatedScrollDown",
  components: {
    ArrowDownIcon
  }
};
</script>
<style scoped lang="scss">
@import "../../styles/imports";

body {
  overflow-x: hidden;
  background: none;
}

@keyframes mouse-animation {
  0% {
    transform: translateY(-6px);
    opacity: 0;
  }
  30%,
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(6px);
    opacity: 0;
  }
}

.mouse-animation {
  cursor: pointer;
  display: none;
  width: 22px;
  height: 37px;
  border: 2px solid $border-color;
  border-radius: 11px;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
  &:after {
    $width: 2px;
    content: "";
    width: $width;
    height: 6px;

    position: absolute;
    top: 12px; // starts where rounded border of container stops
    left: calc(
      50% - #{$width / 2}
    ); // subtract half of width to move it to center.

    background: $white;
    animation: mouse-animation 2.2s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  @include breakpoint(md) {
    display: block;
  }
}

.head-arrow {
  bottom: 40px;
  width: 15px;
  right: 50.5%;
  height: 20px;
  opacity: 0.6;
  animation: arrow-animation 1.1s infinite alternate;
}
</style>

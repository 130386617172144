<template>
  <section>
    <div class="story-grid">
      <div class="title">Success Stories</div>
      <div class="info">
        <EmphasizedInfoBlock class="my-1" v-html="$t('mainText')" />
      </div>
      <div v-for="(story, key) in stories" :key="key" :class="`s${key}`">
        <StoryItem v-model="hoveredItem" :story="story" />
      </div>
    </div>
  </section>
</template>

<script>
import StoryItem from "@/components/startseite/StoryItem";
import imgDigitalisierungImHandwerk from "@/assets/img/story/story-preview/story-digitalisierung-im-handwerk.jpg";
import imgDigitaleProzessoptimierung from "@/assets/img/story/story-preview/digitale-prozessoptimierung-fuer-energieversorger.jpg";
import imgMitVrZurBootsmesse from "@/assets/img/story/story-preview/mit-virtual-reality-zur-bootsmesse.jpg";
import EmphasizedInfoBlock from "@/components/startseite/EmphasizedInfoBlock";

export default {
  name: "Stories",
  components: { EmphasizedInfoBlock, StoryItem },
  data() {
    return {
      hoveredItem: "story-digitalisierung-im-handwerk",
      stories: [
        {
          route: "story-digitalisierung-im-handwerk",
          title: this.$t("stories.story-digitalisierung-im-handwerk"),
          bg: imgDigitalisierungImHandwerk
        },
        {
          route: "story-mit-virtual-reality-zur-bootsmesse",
          title: this.$t("stories.story-mit-virtual-reality-zur-bootsmesse"),
          bg: imgMitVrZurBootsmesse
        },
        {
          route: "story-digitale-prozessoptimierung-fuer-energieversorger",
          title: this.$t(
            "stories.story-digitale-prozessoptimierung-fuer-energieversorger"
          ),
          bg: imgDigitaleProzessoptimierung
        }
      ]
    };
  },
  i18n: {
    messages: {
      de: {
        mainText: `
        <em>Wir lieben es, echte Kundenprobleme zu lösen!</em> Daran arbeiten wir täglich - mit Neugier, Ehrgeiz und großem Erfolg.
        `,
        stories: {
          "story-digitale-prozessoptimierung-fuer-energieversorger":
            "Prozesse effizienter gestalten",
          "story-mit-virtual-reality-zur-bootsmesse":
            "Mit Virtual Reality zur Bootsmesse",
          "story-digitalisierung-im-handwerk":
            "Die App für das Elektro-Handwerk"
        }
      },
      en: {
        mainText: `
        <em>We love to solve our customers’ problems!</em> And our customers love how we work – with curiosity, ambition and great success.
        `,
        stories: {
          "story-digitale-prozessoptimierung-fuer-energieversorger":
            "Processes optimized to be more efficient",
          "story-mit-virtual-reality-zur-bootsmesse":
            "Virtual reality to attend a houseboat trade fair",
          "story-digitalisierung-im-handwerk":
            "An app for the electrical trades"
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/imports";

.story-grid {
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto repeat(3, 1fr);

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 360px);
    grid-row-gap: 0;
    grid-template-areas:
      "s0 s0"
      "title s1"
      "info s2";

    .s0 {
      grid-area: s0;
    }
    .s1 {
      grid-area: s1;
    }
    .s2 {
      grid-area: s2;
    }
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 360px);
    grid-template-areas:
      "s0 s0 title s1"
      ". info s2 .";
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  color: $main-color;
  text-align: center;
  height: 100px;

  @include breakpoint(md) {
    height: 100%;
    background: $white;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    grid-area: title;
  }
}

.info {
  @include breakpoint(md) {
    grid-area: info;
    align-self: center;
  }
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="451.843"
    height="257.567"
    clip-rule="evenodd"
    fill-rule="evenodd"
    image-rendering="optimizeQuality"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    viewBox="0 0 11955 6814.8"
  >
    <path
      d="M5977.5 6814.8c-214.26 0-428.49-81.81-591.85-245.08l-5140.4-5140.5c-327-327-327-857.17 0-1184 326.87-326.87 856.93-326.87 1184 0l4548.3 4548.6 4548.4-4548.4c327-326.87 857.01-326.87 1183.8 0 327.16 326.87 327.16 857.04 0 1184l-5140.4 5140.5c-163.43 163.3-377.66 244.92-591.84 244.92z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDownIcon"
};
</script>

<template>
  <div>
    <intro-video />
    <div class="container mt-4 ">
      <FollowUpWithUs class="mb-4 my-md-5" />
      <OurClients class="mb-4 mb-md-5" />
      <Stories class="mb-4 mb-md-5" />
      <ChatBot @onFinish="onFinish" class="mb-4 mb-md-5" />
    </div>
    <FooterStart />
  </div>
</template>

<script>
import MfLogo from "@/components/logos/MfLogo";
import IntroVideo from "@/components/startseite/IntroVideo";
import FollowUpWithUs from "@/components/startseite/FollowUpWithUs";
import OurClients from "@/components/startseite/OurClients";
import FooterStart from "@/components/startseite/FooterStart";
import { layoutState } from "@/components/layouts/layoutStyles";

import { getClientHeight } from "@/utils/animationHelper";
import { metaInfo } from "@/utils/titleHelper";
import Stories from "@/components/startseite/Stories";
import { sendForm } from "@/utils/webhookHelper";
import ChatBot from "@/components/chatbot/";

export default {
  name: "Startseite",
  components: {
    ChatBot,
    Stories,
    OurClients,
    FollowUpWithUs,
    IntroVideo,
    FooterStart
  },
  data: () => ({
    scrollPos: window.scrollY,
    menu: false,
    isIntro: true,
    isShort: false,
    touchY: 0,
    contentHeight: getClientHeight()
  }),
  metaInfo() {
    return metaInfo(this.$t("title"), this.$t("descr"));
  },
  computed: {
    isShortLogo() {
      return this.scrollPos > this.contentHeight * 0.2;
    },
    isOverVideo() {
      return this.scrollPos < this.contentHeight;
    },
    slogan() {
      if (this.isShortLogo) {
        return this.$t("slogan.digitalInnovation");
      }
      return "";
    },
    logo() {
      return {
        is: MfLogo,
        props: {
          color: "#fff",
          class: ["mainlogo", this.isShortLogo && "short"],
          isShort: this.isShortLogo
        }
      };
    }
  },
  watch: {
    isOverVideo: "setHeaderBackground",
    logo: "setLogo",
    slogan(slogan) {
      layoutState.slogan = slogan;
    }
  },
  created() {
    this.handleScroll();
    layoutState.setPageConfig("dark", false);
    this.setLogo(this.logo);
    this.setHeaderBackground(this.isOverVideo);
    layoutState.slogan = this.slogan;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  activated() {
    setTimeout(() => {
      window.addEventListener("scroll", this.handleScroll);
    }, 1000);
  },
  deactivated() {
    this.scrollPos = 0;
    window.removeEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async onFinish(answers) {
      try {
        await sendForm(answers, `Startseite`);
      } catch (e) {
        // TODO: Error handler
      }
    },
    handleScroll() {
      this.scrollPos = window.scrollY;
    },
    toggle() {
      layoutState.isSubPage = !layoutState.isSubPage;
    },
    setHeaderBackground(isOverVideo) {
      if (isOverVideo) {
        layoutState.overruleTheme({
          backgroundHeader: "transparent"
        });
      } else {
        layoutState.overruleTheme({});
      }
    },
    setLogo({ is, props, events }) {
      layoutState.setLogo(is, props, events);
    }
  },
  i18n: {
    messages: {
      de: {
        title: "Web- und App-Entwicklung mit optimaler UX",
        descr:
          "Wir übersetzen komplexe Prozesse und Anforderungen in smarte Benutzeroberflächen, die technisch ausgezeichnet performen."
      },
      en: {
        title: "Web and app development with optimal UI designs",
        descr:
          "We turn complex requirements and processes into great applications with polished user interfaces and optimized performance."
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/imports";
</style>

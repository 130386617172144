<template>
  <section>
    <div class="v-grid">
      <div class="col-2 col-offset">
        <EmphasizedInfoBlock class="mb-4">
          <em class="d-block">{{ $t("title") }}</em>
          {{ $t("text") }}
        </EmphasizedInfoBlock>
        <div class="clients">
          <div
            v-for="(client, index) in clients"
            :key="index"
            class="logos-col"
          >
            <img
              :alt="client.name"
              :title="client.name"
              :src="client.img.default"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import EmphasizedInfoBlock from "@/components/startseite/EmphasizedInfoBlock";

export default {
  name: "OurClients",
  data() {
    return {
      clients: []
    };
  },
  components: {
    EmphasizedInfoBlock
  },

  async created() {
    this.clients = [
      {
        name: "Elsazeit",
        img: await import("@/assets/img/sponsors/elsazeit.svg")
      },
      {
        name: "Deutsches Forschungsinstitut für Künstliche Intelligenz",
        img: await import("@/assets/img/sponsors/dfki.svg")
      },
      {
        name: "Gründungsbüro TU & HS KL",
        img: await import("@/assets/img/sponsors/GruendungsbueroKL.svg")
      },
      {
        name: "Fraunhofer ITWM",
        img: await import("@/assets/img/sponsors/fraunhofer.svg")
      },
      {
        name: "GMV - Gesellschaft für medizintechnische Versorgung mbH",
        img: await import("@/assets/img/sponsors/gmv.svg")
      },
      {
        name: "IHK Pfalz",
        img: await import("@/assets/img/sponsors/ihkpfaz.svg")
      },
      {
        name: "Nardini Klinikum GmbH",
        img: await import("@/assets/img/sponsors/nardini.svg")
      },
      {
        name: "Sharp Reflections GmbH",
        img: await import("@/assets/img/sponsors/sharpreflections.svg")
      },
      {
        name: "ThinkParQ GmbH",
        img: await import("@/assets/img/sponsors/thinkparq.svg")
      },
      {
        name: "TU Kaiserslautern",
        img: await import("@/assets/img/sponsors/TU.svg")
      },
      {
        name: "VG Wörrstadt",
        img: await import("@/assets/img/sponsors/vgwoerrstadt.svg")
      },
      {
        name: "Wendeware AG",
        img: await import("@/assets/img/sponsors/wendewareAG.svg")
      }
    ];
  },
  i18n: {
    messages: {
      de: {
        title: "Unsere Kunden kommen aus verschiedenen Branchen.",
        text: `Wir realisieren Projekte für kleine und große Unternehmen, für
          wissenschaftliche Institute aber auch für innovative, solide
          finanzierte Start-Ups.`
      },
      en: {
        title: "Our customers represent a wide range of industries.",
        text:
          "We plan and execute projects for small and large companies, scientific institutes, and innovative, well-funded startups."
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../styles/imports";

.clients {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include breakpoint(md) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.logos-col {
  width: 80%;
  height: 60px;
  img {
    max-width: 100%;
    max-height: 100%;
    opacity: 0.8;
  }
  @include breakpoint(md) {
    width: 100%;
  }
}
</style>

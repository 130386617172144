<template>
  <section id="software">
    <div class="v-grid">
      <div class="col-2 col-offset">
        <div class="mb-2 mb-md-4">
          <EmphasizedInfoBlock v-html="$t('mainText')" />
        </div>
      </div>
    </div>

    <div class="mb-md-5 v-grid">
      <router-link
        :to="{ name: btn.item }"
        class="col-2 software-block h-100 text-center"
        v-for="btn in buttons"
        :key="btn.item"
      >
        <div class="software-img">
          <component :is="btn.icon" />
        </div>
        <div class="title">{{ $t(`buttons.${btn.item}.title`) }}</div>
        <div class="d-inline-block subtitle">
          {{ $t(`buttons.${btn.item}.text`) }}
        </div>
      </router-link>
    </div>
  </section>
</template>
<script>
import ProjektStartenLogo from "@/components/logos/ProjektStartenLogo.vue";
import TeamErweiternLogo from "@/components/logos/TeamErweiternLogo.vue";
import EmphasizedInfoBlock from "@/components/startseite/EmphasizedInfoBlock";

export default {
  name: "FollowUpWithUs",
  components: {
    EmphasizedInfoBlock,
    ProjektStartenLogo,
    TeamErweiternLogo
  },
  data() {
    return {
      buttons: [
        {
          item: "teamErweitern",
          icon: "TeamErweiternLogo"
        },
        {
          item: "projektStarten",
          icon: "ProjektStartenLogo"
        }
      ]
    };
  },
  i18n: {
    messages: {
      de: {
        mainText: `
            <em>Digitale Innovation leicht gemacht – </em> mit smarten Ideen und
            effizienten Softwarelösungen. Wir übersetzen komplexe Prozesse und
            Anforderungen in hochwertige Applikationen, mit kreativem Design und
            intuitiver Nutzerführung – pünktlich und budgetgerecht.
        `,
        buttons: {
          teamErweitern: {
            title: "Team erweitern",
            text: "Auslastungsspitzen bremsen Sie aus?"
          },
          projektStarten: {
            title: "Projekt starten",
            text: "Digitales Produkt in Planung?"
          }
        }
      },
      en: {
        mainText: `
        <em>Digital innovation made easy - </em>with bright ideas and efficient
        software solutions. We turn complex requirements and processes into polished
        applications with creative designs and intuitive interfaces – on time and on budget.
        `,
        buttons: {
          teamErweitern: {
            title: "Extend your team",
            text: "Is your workload slowing you down?"
          },
          projektStarten: {
            title: "Start a project",
            text: "Are you planning a digital project?"
          }
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../styles/imports";

.v-grid {
  @include breakpoint(lg) {
    flex-wrap: nowrap;
  }
}

.software-block {
  display: block;
  background-color: $body-bg;
  padding: 30px 10px;

  &:hover {
    background-color: $main-color;
    color: $white;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
  }

  + .software-block {
    margin-top: 15px;
  }

  @include breakpoint(md) {
    width: 100%;
    padding: 100px 10px;
  }
  @include breakpoint(lg) {
    flex: 0 0 calc(50% - 1px);
    padding: 100px 20px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    margin: 0 0.5px 0 0;

    + .software-block {
      margin: 0 0 0 0.5px;
    }
  }
}

.title {
  font-size: $font-size-base;
  line-height: 45px;
  @include breakpoint(md) {
    font-size: $font-size-xl;
    line-height: $font-size-xl;
  }
}
.subtitle {
  font-size: $font-size-sm;
  text-decoration: underline;
}

.software-img {
  width: 120px;
  height: 80px;
  margin: 0 auto 20px;
  @include breakpoint(md) {
    width: 135px;
    height: 95px;
    margin: 0 auto 15px;
  }
}
.software-img svg g {
  transition: all 0.2s ease-in-out;
}
::v-deep .software-block:hover svg g {
  fill: $white;
}
</style>

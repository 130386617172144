<template>
  <svg viewBox="0 0 361 258.6" xmlns="http://www.w3.org/2000/svg">
    <g fill="#05a4ff">
      <path
        d="M328.8 33.7a32.2 32.2 0 11-22.8 9.4 32.1 32.1 0 0122.8-9.4zm17.3 14.9a24.5 24.5 0 107.2 17.3 24.4 24.4 0 00-7.2-17.3zM18.5 171.5a18.5 18.5 0 11-13.1 5.4 18.5 18.5 0 0113.1-5.4zm7.7 10.9a10.8 10.8 0 103.2 7.7 10.8 10.8 0 00-3.2-7.8zM174.7 201.3a28.7 28.7 0 11-20.3 8.4 28.6 28.6 0 0120.3-8.4zm14.8 13.8a21 21 0 106.1 14.8 20.9 20.9 0 00-6.1-14.8zM165 0a20.4 20.4 0 11-14.5 6A20.4 20.4 0 01165 0zm9 11.4a12.8 12.8 0 103.7 9 12.7 12.7 0 00-3.7-9zM299 155.5a20.4 20.4 0 11-14.5 6 20.4 20.4 0 0114.5-6zm9 11.4a12.8 12.8 0 103.7 9 12.7 12.7 0 00-3.6-9zM127.8 123.1a19.5 19.5 0 11-13.8 5.7 19.4 19.4 0 0113.8-5.7zm8.3 11.1a11.8 11.8 0 103.5 8.3 11.7 11.7 0 00-3.5-8.2z"
      />
      <path
        d="M157.2 37.2L25.9 178.6l-5.6-5.2L151.6 32zM33 191.8l118.5 28.6-1.8 7.4-118.5-28.5zM324.8 93.4l-15.5 68.1-7.4-1.7 15.5-68zM197.4 220.7l88.7-36.3 2.9 7-88.7 36.4zM160.9 209.3l-28.8-51.2 6.7-3.7 28.8 51.1zM140.9 133L301 72.5l2.7 7.1-160.1 60.6zM182.2 20.4l120.3 34.1-2 7.4-120.3-34.1z"
      />
      <path d="M178 31.2l111.2 131.6-5.9 5L172.1 36.1z" />
      <path
        d="M310.4 87.4L191.5 212.1l-5.5-5.2L304.9 82.1zM172.7 37v168.9H165V37zM158.2 35.4L136 129.1l-7.5-1.7 22.2-93.7zM27.5 175.9l84.8-32.1 2.7 7.2-84.8 32.1z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "TeamErweiternLogo"
};
</script>
